import $ from 'jquery'
//To Top Button
$(document).ready(function() {

    $('.to-top').click( function() {
        $('html, body').animate({scrollTop: '0'}, 600);
    });


    $(window).on("resize", function () {
        var mainwidth = $( window ).width()
        var contwidth = $("#start .grid-container").width();
        var margin = ((mainwidth - contwidth) / 2);
        $("#to-top, .arrow-right").css("margin-right", margin);
        $("#hambox").css("margin-left", (margin - 1));
    }).resize() ;




    $(".arrowdown").on("click", function(e) {
        $('html, body').animate({
            scrollTop: $(this).parent().next().offset().top  - 0
        }, 800);
    });

});