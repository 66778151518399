import { ready } from "./utils.js";

ready(() => {
  const hamburger = document.getElementById("hamburger");
  const hambg = document.getElementById("hambg");

  if (hamburger) {
    hamburger.addEventListener("click", () => {
      hamburger.classList.toggle("is-active");
      hambg.classList.toggle("is-active");
    });
  }
});
