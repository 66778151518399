import $ from 'jquery'

$(document).ready(function () {
  //set class opaque on scroll
  $(document).on('scroll', function() {
    if($(this).scrollTop() > 500) {
      $('.to-top').addClass('opaque');
      $('.hamburger-container').addClass('opaque');
    } else {
      $('.to-top').removeClass('opaque');
      $('.hamburger-container').removeClass('opaque');
    }

    if($(this).scrollTop() > 100) {
      $('.arrow-right').addClass('opaque');
    } else {
      $('.arrow-right').removeClass('opaque');
    }
  });

;

});
